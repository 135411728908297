import React from 'react';
import Layout from "../../common/Layout";

const Micronet = () => {
    return (
        <>
           
            <Layout>
                <div className="main-content micronet">
                #micronet_content#
                </div>
            </Layout>
        </>
    )
}
export default Micronet;